import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import CaseResultSmall from "../components/case-result-small";
import SidebarContactForm from "../components/sidebar-contact-form";

class ResultsPage extends React.Component {
  render() {
    const caseResults = get(this, "props.data.allContentfulCaseResult.edges");
    const page = get(this, "props.data.contentfulPage");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${page.title}`}
          keywords={`${page.keywords ? page.keywords.join() : ""}`}
          description={page.seoDescription}
        />
        <section className="section">
          <div className="container has-text-centered py-4">
            <h1 className="title">{page.title}</h1>
            <p className="subtitle ">{renderRichText(page.body)}</p>
          </div>
        </section>
        <div class="columns px-4">
          <div class="column">
            <div className="content">
              {caseResults.map(({ node }) => {
                return (
                  <>
                    <hr className="has-background-grey-lighter" />
                    <CaseResultSmall caseResult={node} />
                  </>
                );
              })}
            </div>{" "}
            <div className="content">
              {page.footer !== null && renderRichText(page.footer)}
            </div>
          </div>
          <div class="column is-one-third">
            <SidebarContactForm />
          </div>
        </div>
      </Layout>
    );
  }
}

export default ResultsPage;

export const pageQuery = graphql`
  query CaseResultsPageQuery {
    contentfulPage(slug: { eq: "our-victories" }) {
      slug
      title
      keywords
      seoDescription
      image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: TRACED_SVG
        )
      }
      body {
        raw
      }
      footer {
        raw
      }
    }
    allContentfulCaseResult {
      edges {
        node {
          title
          tags
          slug
          body {
            raw
          }
          files {
            description
            title
            file {
              url
            }
          }
          image {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              resizingBehavior: SCALE
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`;
