import React from "react";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const CaseResultSmall = ({ caseResult }) => (
  <article className="media">
    <div className="media-content">
      <div className="content">
        <h2 className="title has-text-primary mb-1">
          <Link
            className="has-text-primary"
            to={`/our-victories/${caseResult.slug}/`}
          >
            {caseResult.title}
          </Link>
        </h2>
        {caseResult.tags !== null &&
          caseResult.tags.map((obj) => (
            <span className="tag is-primary is-capitalized mr-1 has-text-weight-semibold">
              {obj}
            </span>
          ))}

        {renderRichText(caseResult.body)}
      </div>
    </div>
  </article>
);

export default CaseResultSmall;
